// import { useSession } from 'next-auth/react';
// import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import {
  Image,
  Button,
  Input,
  Container,
  Text,
  Stack,
  FormControl,
  VStack,
  FormErrorMessage,
  Center,
  useToast,
} from '@chakra-ui/react';
import { sendWarnMeReq } from '@services/user';
import logger from '@logger/logger';
import { useState } from 'react';

export default WarnMeModule;

function WarnMeModule() {
  const toast = useToast()
  const ENV = process.env.NEXT_PUBLIC_NODE_ENV;
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const [hasSendEmail, setHasSendEmail ] = useState(false);
  // const router = useRouter();

  // const { status } = useSession();
  // if (status === 'authenticated') {
  //   router.push('/');
  // }

  const onSubmit = (values: any) => {
    return sendWarnMeVerification(values.email);
  };

  async function sendWarnMeVerification(email: string) {
    if (!email) return;
    try{
      const value =  await sendWarnMeReq({ email }); 
      if(value != undefined){
        setHasSendEmail(true)
        // toast({
        //   title: 'Sucesso!',
        //   description: "Email registrado com Sucesso!",
        //   status: 'success',
        //   duration: 9000,
        //   isClosable: true,
        // })
      }else {
        toast({
          title: 'Falha!',
          description: "Falha ao registrar email!",
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    }catch(e){
      logger.error(e)
    }
  }

  const renderForm = () => {
    return (<>
    <VStack spacing={0} mb="10px">
          <Text>
          ¿Quieres participar <strong> en los mejores sorteos </strong>en el momento más esperado del fútbol y
            competir por  <strong> cientos </strong> de premios?
          </Text>
          <Text>
          ¡Fácil Simplemente ingrese  <strong> su mejor correo electrónico </strong> a continuación para que pueda ser el
            primero en saber una vez que la promoción comience el <strong> 14 de noviembre! </strong>
          </Text>
        </VStack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={[2, 5]}>
            <FormControl
              isInvalid={errors.email ? true : false}
              textAlign={'center'}
            >
              <Input
                id="email"
                maxW={450}
                placeholder="seu@email.com"
                errorBorderColor="red.500"
                size={['md', 'lg']}
                // onChange={(e) => setEmail(e.target.value)}
                {...register('email', {
                  required: 'Ingresa una dirección válida',
                  pattern: {
                    value:
                      ENV == 'production'
                        ? /^[aA-zZ0-9._%-]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,4}$/
                        : /^[aA-zZ0-9._%-+]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,4}$/,
                    message: 'Digite um e-mail válido',
                  },
                })}
              />
              <FormErrorMessage
                mt={[1, 2]}
                ml={{ base: 0, md: 6 }}
                fontSize={['xs', 'md']}
              >
                {errors.email && (errors.email.message as string)}
              </FormErrorMessage>
            </FormControl>

            <Button
              textAlign={'center'}
              colorScheme="blue"
              isLoading={isSubmitting}
              loadingText="cargando"
              height="48px"
              width={['250px', '360px']}
              spinnerPlacement="start"
              size={['md', 'xl']}
              type="submit"
              my="20px"
            >            
            ¡AVISAME!
            </Button>
          </VStack>
        </form>

        <Text fontSize={['xs', 'sm']} px={2} textAlign={'center'}>
        Al hacer clic en el botón "¡AVISAME!", acepta recibir boletines de <strong> Promoção #EuSouCraque!</strong>  e de outras promoções da <strong>  Máquina de Promos </strong>  e de seus parceiros comerciais. Se, depois de cadastrado, você não quiser receber mais os emails da Máquina de Promos, basta fazer o pedido, enviando uma mensagem para <b><u>contato@maquinadepromos.com.br</u></b>
        </Text>
    </>)
  }


  const renderSendEmail = () => {
    return (<>
      <VStack justifyContent="center" fontWeight={600} spacing={0}>
        <Text > Seu email foi registrado com sucesso. </Text>
        <Text > Agora, é só aguardar! </Text>
        <Text pt="20px"> Nos vemos dia 14, craque! ⚽ </Text>
      </VStack>
    </>)
  }

  return (
    <Container color="white">
      <Center>
      <Image src={'/imgs/topo.png'} h="100px" />
      </Center>
      <Stack mt="30px" spacing={[5]} direction={['column']} textAlign={'center'}>
        {hasSendEmail?renderSendEmail():renderForm()}
      <Image src={'/imgs/rodape.png'} />
      <Text pt="20px" fontSize={['xs', 'sm']} px={2} textAlign={'center'}>
        Máquina de Promos é a solução de Promotion as a Service da Zed Brasil Comunicação Ltda. Para falar conosco, envie um email para <b><u>contato@maquinadepromos.com.br</u></b>
      </Text>
      </Stack>
    </Container>
  );
}
